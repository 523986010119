window.addEventListener('DOMContentLoaded', () => {
    gsap.registerPlugin(ScrollTrigger);

    const preloader = document.querySelector('.preloader')

    if (preloader) {
        gsap.to('.preloader', {
            opacity: 0,
            duration: 0.5,
            delay: 3.5,
            onComplete: () => {
                document.querySelector('.preloader').style.display = 'none';
            }
        });
    }

    const cards = document.querySelector('.w-card');

    if (cards) {
        gsap.fromTo(".w-card", {
            x: 100,
            opacity: 0
        }, {
            x: 0,
            opacity: 1,
            duration: 0.35,
            ease: "power4.out",
            stagger: 0.1,
            delay: 3.8
        });
    }

    const track = document.querySelector('.welcome__track');

    if (track && window.innerWidth > 767) {
        const screen = document.querySelector('.welcome__screen');
        const screenWidth = screen.offsetWidth;
        const trackWidth = track.offsetWidth;
        const scrollWidth = trackWidth - screenWidth;

        const multiplier = screen.offsetWidth < window.innerWidth
            ? (window.innerWidth - screen.offsetWidth)
            : -(screen.offsetWidth - window.innerWidth);

        const scrollTween = gsap.to(track, {
            x: `-${scrollWidth - multiplier}px`,
            ease: "none",
            scrollTrigger: {
                trigger: ".welcome",
                pin: true,
                scrub: 1.2,
                start: '50% 50%',
                end: '+=6000',
            }
        });

        const cards = document.querySelectorAll('.w-card');

        cards.forEach((card, index) => {
            const randomY = () => {
                let randomY;
                do {
                    randomY = gsap.utils.random(-500, 500);
                } while (Math.abs(randomY) <= 300);
                return randomY;
            };

            const randomRotation = () => {
                let randomRotation;
                do {
                    randomRotation = gsap.utils.random(-30, 30);
                } while (Math.abs(randomRotation) <= 15);
                return randomRotation;
            };

            const startOffset = index === 0 ? index + 10 : index * 10;
            const endOffset = index;

            gsap.to(card, {
                y: randomY,
                rotation: randomRotation,
                ease: "none",
                scrollTrigger: {
                    trigger: card,
                    containerAnimation: scrollTween,
                    start: `left ${startOffset}%`,
                    end: `right ${endOffset}%`,
                    scrub: true,
                    id: index,
                }
            });
        });

        const navLinkAnimation = () => {
            const animation = gsap.fromTo(".welcome__screen-nav-link", {
                y: 30,
                x: 10,
                opacity: 0
            }, {
                y: 0,
                x: 0,
                opacity: 1,
                duration: 0.35,
                ease: "power4.out",
                stagger: 0.1,
                delay: 0.3
            });

            ScrollTrigger.create({
                trigger: ".welcome__screen-nav-link",
                animation: animation,
                start: `left 97%`,
                containerAnimation: scrollTween,
                toggleActions: "play reverse play reverse",
            });
        };

        const socLinkAnimation = () => {
            const animation = gsap.fromTo(".welcome__screen-soc-link", {
                y: 30,
                x: 10,
                opacity: 0
            }, {
                y: 0,
                x: 0,
                opacity: 1,
                duration: 0.35,
                ease: "power4.out",
                stagger: 0.2,
            });

            ScrollTrigger.create({
                trigger: ".welcome__screen-soc-link",
                animation: animation,
                start: `left 97%`,
                containerAnimation: scrollTween,
                toggleActions: "play reverse play reverse"
            });
        };

        const titleAnimation = () => {
            const animation = gsap.fromTo(".js-reveal-part", {
                opacity: 0,
                y: -20,
                x: -20
            }, {
                y: 0,
                x: 0,
                opacity: 1,
                duration: 0.24,
                stagger: 0.15,
                ease: "power4.out",
            });

            ScrollTrigger.create({
                trigger: ".welcome__screen",
                animation: animation,
                start: `left 60%`,
                containerAnimation: scrollTween,
                toggleActions: "play reverse play reverse"
            });
        };

        const btnAnimation = () => {
            const animation = gsap.fromTo(".welcome__screen-btn", {
                opacity: 0,
                y: 30,
                x: 30
            }, {
                y: 0,
                x: 0,
                opacity: 1,
                duration: 0.5,
            });

            ScrollTrigger.create({
                trigger: ".welcome__screen",
                animation: animation,
                start: `left 50%`,
                containerAnimation: scrollTween,
                toggleActions: "play reverse play reverse"
            });
        };

        const infoAnimation = () => {
            const animation = gsap.fromTo(".welcome__screen-note", {
                opacity: 0,
                y: 30,
                x: 30
            }, {
                y: 0,
                x: 0,
                opacity: 0.9,
                duration: 0.35,
                ease: "power4.out",
                stagger: 0.1,
                delay: 0.3
            });

            ScrollTrigger.create({
                trigger: ".welcome__screen-note",
                animation: animation,
                start: `left 90%`,
                containerAnimation: scrollTween,
                toggleActions: "play reverse play reverse"
            });
        };

        const logoAnimation = () => {
            const animation = gsap.fromTo(".welcome__screen-logo", {
                opacity: 0,
                y: -30,
                x: -30
            }, {
                y: 0,
                x: 0,
                opacity: 1,
                duration: 0.6,
            });

            ScrollTrigger.create({
                trigger: ".welcome__screen-logo",
                animation: animation,
                start: `left 97%`,
                containerAnimation: scrollTween,
                toggleActions: "play reverse play reverse",
            });
        };

        navLinkAnimation();
        socLinkAnimation();
        titleAnimation();
        btnAnimation();
        infoAnimation();
        logoAnimation();
    }


    const transitionLinks = document.querySelectorAll('.transition-link');

    if (transitionLinks) {
        transitionLinks.forEach(link => {
            const wCard = link.closest('.w-card');
            const wCardPic = wCard.querySelector('.w-card__pic');
            
            link.addEventListener('click', (e) => {
                e.preventDefault();

                gsap.killTweensOf('*');

                const fakeTopPic = document.querySelector('.top__pic');
                const width = fakeTopPic.offsetWidth;
                const height = fakeTopPic.offsetHeight;

                wCard.style.zIndex = 10

                const respOffset = () => {
                    if (window.innerWidth > 1279) return 15
                    if (window.innerWidth > 767) return 10
                    return 5
                }

                const tl = gsap.timeline();

                tl.to(wCard, {
                    rotate: 0,
                    duration: .2,
                    onComplete: () => { 
                        const cardRect = wCardPic.getBoundingClientRect();
                        console.log(cardRect)

                        gsap.to(wCardPic, {
                            width: width,
                            height: height,
                            top: -cardRect.top + respOffset(),
                            left: -cardRect.left + respOffset(),
                            duration: .6,
                            ease: 'none',
                            onStart: () => {
                                wCardPic.classList.add('_decor')
                                wCard.classList.add('_highlight')
                                track.classList.add('_redirect')
                            },
                            onComplete: () => {
                                window.location.href = link.href;
                            }
                        });
                    }
                })
                
            });
        });
    }

    const topDecor = document.querySelector('.js-top-decor');

    if (topDecor) {
        gsap.fromTo('.js-top-decor', {
            opacity: 0,
            y: 30
        }, {
            opacity: 1,
            y: 0,
            stagger: 0.15,
        })
    }
});