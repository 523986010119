document.addEventListener("DOMContentLoaded", function () {

    if (window.innerWidth < 1280) return

    new Swiper('.js-slider', {
        spaceBetween: 50,
        slidesPerView: "auto",
        allowTouchMove: true,
        navigation: {
            nextEl: ".r-slider__arr",
        },
        pagination: {
            el: ".r-slider__pagination",
            clickable: true
          },
    });
});