document.addEventListener('DOMContentLoaded', function() {
    const connectButtons = document.querySelectorAll('.js-connect');
    const popup = document.querySelector('.js-popup');
    const closePopups = document.querySelectorAll('.js-close-popup');

    connectButtons.forEach((button) => {
        button.addEventListener('click', function(e) {
            e.stopPropagation();
            e.preventDefault();
            if (popup.classList.contains('_opened')) {
                popup.classList.remove('_opened');
            } else {
                popup.classList.add('_opened');
            }
        });
    });

    closePopups.forEach((closePopup) => {
        closePopup.addEventListener('click', function(e) {
            popup.classList.remove('_opened');
        });
    });

    document.addEventListener('click', function(e) {
        if (!e.target.classList.contains('js-connect')) {
            if (!popup.contains(e.target)) {
                popup.classList.remove('_opened');
            }
        }
    });
});