
document.addEventListener("DOMContentLoaded", function () {
    // var center = { lat: 40.7128, lng: -74.0060 }; // Нью-Йорк
    
    // var map = new GMaps({
    //     div: '#map',
    //     lat: center.lat,
    //     lng: center.lng
    // });
    
    // // Загрузка стилей Snazzy Maps
    // var styles = [
    //     {
    //         "featureType": "road",
    //         "stylers": [
    //             {
    //                 "hue": "#5e00ff"
    //             },
    //             {
    //                 "saturation": -79
    //             }
    //         ]
    //     },
    //     {
    //         "featureType": "poi",
    //         "stylers": [
    //             {
    //                 "saturation": -78
    //             },
    //             {
    //                 "hue": "#6600ff"
    //             },
    //             {
    //                 "lightness": -47
    //             },
    //             {
    //                 "visibility": "off"
    //             }
    //         ]
    //     },
    //     {
    //         "featureType": "road.local",
    //         "stylers": [
    //             {
    //                 "lightness": 22
    //             }
    //         ]
    //     },
    //     {
    //         "featureType": "landscape",
    //         "stylers": [
    //             {
    //                 "hue": "#6600ff"
    //             },
    //             {
    //                 "saturation": -11
    //             }
    //         ]
    //     },
    //     {},
    //     {},
    //     {
    //         "featureType": "water",
    //         "stylers": [
    //             {
    //                 "saturation": -65
    //             },
    //             {
    //                 "hue": "#1900ff"
    //             },
    //             {
    //                 "lightness": 8
    //             }
    //         ]
    //     },
    //     {
    //         "featureType": "road.local",
    //         "stylers": [
    //             {
    //                 "weight": 1.3
    //             },
    //             {
    //                 "lightness": 30
    //             }
    //         ]
    //     },
    //     {
    //         "featureType": "transit",
    //         "stylers": [
    //             {
    //                 "visibility": "simplified"
    //             },
    //             {
    //                 "hue": "#5e00ff"
    //             },
    //             {
    //                 "saturation": -16
    //             }
    //         ]
    //     },
    //     {
    //         "featureType": "transit.line",
    //         "stylers": [
    //             {
    //                 "saturation": -72
    //             }
    //         ]
    //     },
    //     {}
    // ];
    
    // map.setOptions({ styles: styles });


    mapboxgl.accessToken = 'pk.eyJ1IjoiZGltYTE1MDEiLCJhIjoiY2p4aXcydHZmMDA1NjN0bzdrOHUxeWs3eCJ9.1CwUTHoTLPGSpkx0_4I8eg'; // Установите ваш токен доступа сюда

    var map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11', // Стиль по умолчанию
        center: [-74.5, 40], // Установка центра карты
        zoom: 12 // Установка масштаба
    });

    var marker = new mapboxgl.Marker()
        .setLngLat([-74.5, 40]) // Установка координат маркера
        .addTo(map);
    
})