document.addEventListener("DOMContentLoaded", function () { 
    const header = document.querySelector('.js-header');
    const opener = document.querySelector('.header__opener');

    opener.addEventListener('click', function() {
        header.classList.toggle('_opened');
        opener.classList.toggle('_opened');
    });

    const navLinks = document.querySelectorAll('.header__nav-link');

    navLinks.forEach(link => {
        const href = link.getAttribute('href');
        const currentURL = window.location.href;

        if (currentURL.includes(href)) {
            link.classList.add('_active');
        }
    });
})